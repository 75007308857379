.category-parent{
    padding: 0.6em 0.5em 0.4em 0.5em;
    border: 1px solid lightgrey;
    border-radius: 0.3em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    h6{
        cursor: pointer;
        color: #555;
    }
    .opener{
        color: grey;
        cursor: pointer;
    }
}

.categories{
    position: fixed;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: whitesmoke;
    z-index: 3;
    padding: 5rem;
    background: url('../../assets/images/tape.png')no-repeat;
    background-blend-mode: darken;
    background-size: cover;
    background-color: #333;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    animation: In 0.5s ease-in-out;
    overflow: hidden;
    .category{
        background: whitesmoke;
        border: 1px solid gray;
        box-shadow: 0px 0px 30px 0px #111;
        margin: 1em;
        padding: 1rem;
        border-radius: 3em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.5s;
        cursor: pointer;
        .checker{
            color: green;
        }
        .unCheck{
            color: red;
        }
    }
    .picked{
        background: rgb(36, 100, 36);
        color: white;
        font-weight: bold;
        transition: 0.5s;
    }
}

.closer{
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    padding: 0.1em 0.2em;
    border-radius: 50%;
    border: 1px solid whitesmoke;
    text-shadow: 1px 1px 0px red;
    font-size: xx-large;
    cursor: pointer;
    transition: 0.5s;
    &:hover{
        transform: translate(-50%,-50%) scale(1.1);
    }
}

@keyframes In{
    from{
        top: 60%;
        opacity: 0;
    }
    to{
        top: 50%;
        opacity: 1;
    }
}

@keyframes Out{
    from{
        top: 50%;
        opacity: 1;
    }
    to{
        top: 60%;
        opacity: 0;
    }
}
